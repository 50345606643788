.Floating_Btn{
    background: #88050D!important;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    border-radius: 55px;
    color: wheat!important;
    cursor: pointer;
}

.active_btn{

}
.disactive_btn{
  
}

.chat_space{
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 9999;
    border:1px solid #fff;
    border-radius: 50%;
}

.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 0%);
    z-index: -1;
    filter: saturate(0);
    backdrop-filter: brightness(0.6);
}