@font-face {
  font-family: "JosefinSansRegular";
  src: local("JosefinSansRegular"),
    url("./assets/fonts/JosefinSansRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArabicFont";
  /* src: url("./assets/fonts/GE_SS_Unique_Light_1.otf") format("opentype"); */
  src: url("./assets/fonts/GESSUniqueLight-Light.ttf") format("opentype");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "ArabicFont";
  /* src: url("./assets/fonts/GE_SS_Unique_Bold_5 (1).otf") format("opentype"); */
  src: url("./assets/fonts/GESSUniqueBold-Bold.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  /* background-image: url('./assets/images/imgs//bgImag.png') !important; */
  /* background-size: contain !important; */
  /* font-family: "ArabicFont","JosefinSansRegular" !important; */
  color: #4c4c4c;
}


.en-font {
  font-family: "JosefinSansRegular" !important;
}
.ar-font {
  font-family: "ArabicFont" !important;
}

.ar-font input {
  font-family: "ArabicFont" !important;
  /* color: #f00000 !important; */
}

.en-font input {
  font-family: "JosefinSansRegular" !important;
  /* color: #00f !important; */
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
