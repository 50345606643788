@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.container{
    justify-content: center;
    display: block;
    align-items: center;
    background: transparent!important;
    margin: 150px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 8px #80808061;
    border-radius: 15px;
}
.phoneError{
    color:#83050d
}

.submit_btn{
    background: rgba(136, 5, 13, 1) !important;
    margin-top: 25px!important;
    border-radius: 20px!important;
    height: 56px!important;
    transition: all 0.3s ease-in-out;
}
.submit_btn:hover{
    background: rgb(91, 2, 8) !important;
}
.question_senetense{
    margin-top: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700 !important;
}

.custom_input{
    height: 50px;
}
.custom_input::-ms-expand {
    display: none!important;
    appearance: none!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
}

.error {
    color: #e53e3e;
    font-weight: 100;
    font-size: var(--chakra-fontSizes-sm);
  }
  .error_images {
    color: #e53e3e;
    font-weight: 100;
    font-size: var(--chakra-fontSizes-sm);
    margin: auto 16%;
  }
  
.image{
    width: 50%;
}
.form_flex{
    justify-content: center;
    padding-bottom: 0;
}
.form{
    width: 100%;
    display: flex;
    margin: 0% auto;
    gap: 10%;
    padding: 50px;
    padding-bottom: 0;
}
.form_fields_content{
    width: 80%;
    padding: 20px;
    background: white;
    box-shadow: 1px 0px 8px 0px #ececec;
    border-radius: 5px;
    align-self: center;
}
.form_social_content{
    width: 50%;
    text-align: center;
    justify-content: center;
    text-align: -webkit-center;
}
.form_social_content_header{
    font-weight: 100 !important;
    font-size: 0.9rem;
    color: grey;
    margin-top: -20px;
}

.third_Buttons_div{
    display: grid;
    gap: 5px;
    margin-top: 10px;
}
.third_Buttons{
    gap: 2px!important;
    box-shadow: none!important;
    background: transparent!important;
    border: solid 1px #cfcfcf!important;
    justify-content: center!important;
    text-align: center!important;
    color: black!important;
    font-size: 0.7rem!important;
    display: inline-flex!important;
    border-radius: 10px!important;
}
.third_Buttons_Text {
    width: 20%;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    color: rgba(66, 84, 102, 1);
    font-family: Poppins;
    font-size: 12px;
}


/*--------------------------------------------------/*  I D E N T I T Y  /*--------------------------------------------------*/
.identity_form{

}
.form_fields_container {
    max-height: 66vh;
    overflow-y: scroll;
    background: white;
}

.form_fields_container::-webkit-scrollbar {
    width: 3px !important; /* Adjust as needed */
}

.form_fields_container::-webkit-scrollbar-track {
    background: #ffffff!important;
}

.form_fields_container::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, white, #8f8f8f, #ffffff)!important;
    border-radius: 10px!important;
}

.form_fields_container::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, white, #212121, #ffffff)!important;
}
  
.identity_btn{
    background: rgba(136, 5, 13, 1) !important;
    margin-top: 25px!important;
    border-radius: 20px!important;
    height: 56px!important;
    transition: all 0.3s ease-in-out;
    width: 60%;
    margin: auto 21%;
}
.identity_btn:hover{
    background: rgb(91, 2, 8) !important;
}
.images_box_container{
    display: block;
    width: 100%;
    padding-top: 30px;
    padding: 90px;
}
.image_container{
    width: 40%;
}
.image_step2{
    width: 70%;
    object-fit: scale-down;
    margin: 30% auto;
}
.Identity_heading{
    font-size: 1rem!important;
    font-weight: 600!important;
    /* margin: auto 15%!important; */
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}
.image_box_content{
    width: 100%;
}
.image_box{
    position: relative;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    border-width: 2px;
    border: dashed #c6c2c2;
    height: 20vh;
    width: 100%;
    margin: auto;
    background-size: contain;
    overflow: hidden;
    object-fit: scale-down;
    background-repeat: no-repeat;
    text-align: center;
    justify-content: center;
    background-position-x: center;
}

.image_stock{
    object-fit: contain;
    width: 100%;
    margin: auto;
    max-height: 100px;
    min-height: 100px;
}
.image_preview{
    object-fit: contain;
    width: 100%;
    margin: auto;
    max-height: 180px;
    min-height: 180px;
}
.text_browse{
    color: rgba(31, 70, 144, 1);
    font-weight: bold;
    margin: auto 5px;
    text-decoration: underline;
    /* text-underline-position: under; */
    cursor: pointer;
}
.text_supports_types{
    font-size: 0.7rem;
    color: darkgrey;
}
.text_browse:hover{
    color: rgb(37, 134, 26);
}
.spinner_icon{
    position: absolute;
    float: inline-end;
    margin: auto 90%;
    margin-top: 10px;
}
.delete_icon{
    position: absolute;
    float: inline-end;
    margin: auto 90%;
    margin-top: 10px;
}
.delete_image{
    color: #a92323;
    font-size: 2rem;
    background: aliceblue;
    box-shadow: 0px 0px 8px grey;
    border-radius: 50px;
    padding: 4px;
    cursor: pointer;
}

.error{
    color: #e53e3e;
    font-weight: 100;
    font-size: var(--chakra-fontSizes-sm);
}
.error_images{
    color: #e53e3e;
    font-weight: 100;
    font-size: var(--chakra-fontSizes-sm);
    margin: auto 16%;
}
.submit_btn_identity{
    width: 65%;
    margin: auto 17%;
    margin-top: 20px;
    height: 50px !important;
}

/*--------------------------------------------------/*  O  T  P   /*--------------------------------------------------*/
.otp_form{
    justify-content: center;
    width: 50%;
    display: grid;
    grid-gap: 35px;
    margin: 5% auto;
    text-align: center;
    place-items: center;
}
.otp_icon_box{
    padding: 25px;
    background: rgb(166 135 68 / 12%);
    border-radius: 65px;
}
.otp_icon{
    font-size: 4rem;
    color: #83050d;
}
.otp_h1{
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
}
#input_otp{
    border: solid 2px rgba(136, 5, 13, 1)!important;
    background: rgba(240, 240, 240, 1)!important;
    width: 45px !important;
    height: 45px!important;
    cursor: text!important;
    outline-color: rgb(224 65 74)!important;
    font-size: 1.6rem!important;
}
.otp_btn{
    width: 100%;
    height: 50px;
    border-radius: 20px;
}
.sentense_flex{
    display: flex!important;
    gap: 5px!important;
}
.sentense_flex_li{
    color: rgba(71, 84, 103, 1)!important;
   
}
#sentense_flex_li_clickable{
    color: rgba(136, 5, 13, 1) !important;
    font-weight: bold;
    cursor: pointer;
}
#sentense_flex_li_clickable:hover{
    color: rgb(33, 33, 33) !important;
}
.back{
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
    color: grey;
    transition: all 0.3s ease-in-out;
}
.back:hover{
    color: rgba(136, 5, 13, 1);
}

@media screen and (max-width:1060px) {
 
    .container {
        max-height: max-content;
        width: 80%;
        margin: auto 10%;
        margin-top: 0px;
        margin-bottom: 20px;
        min-height: auto;
    }

    .form{
        display: block;
        padding: 15px;
        margin-top: 35px;
    }
 
   .third_Buttons_Text{
        font-size: 1rem;
        font-family: sans-serif;
        font-weight: 500;
    }
   .form_fields_content{
    width: 100%;
    padding: 0px;
    box-shadow: none;
   }
   .form_social_content{
    width: 100%;
   }
   .image{
    width: 40%;
    }
    .submit_btn{
        height: 45px!important;
    }
}
@media screen and (max-width:800px) {

    .question_senetense{
        font-size: 0.95rem;
    }
    .image{
        width: 50%;
    }
    .third_Buttons_Text{
        font-size: 14px;
    }
}

@media screen and (max-width:700px) {
    .container {
        max-height: max-content;
        width: 100%;
        margin: auto 0%;
        margin-bottom: 20px;
        min-height: auto;
        box-shadow: none;
    }
    .otp_form {
        margin: 12% auto;
    }


}



@media screen and (max-width:576px) {
   
}