.Container{
    width: 80%;
    display: block;
    align-items: center;
    min-height: 100vh;
    padding-top: 80px;
    margin:auto;
    justify-content: center;
    margin-top: 10px;
}
.Content{
    width: 100%;
    display: flex;
    /* gap: 15%; */
    justify-content: space-between;
    /* margin-top: 20px; */
}

.sideMenuContainer{
width: 20%;
}
@media screen and (max-width:860px){
    .Content{
        display: block;
        gap: 0%;
    }
    .sideMenu{
        width: 50%;
        margin-bottom: 25px;

    }
    .sideMenuContainer{
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width:600px){
    .Container{
        padding: 20px;
    }
    .sideMenu{
        width: 50%;
        margin-bottom: 25px;

    }
    .sideMenuContainer{
        width: 100%;
        justify-content: center;
    }
}