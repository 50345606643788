.form{
    width: 85%;
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #12121230;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form_line{
    /* position: relative; */
    margin-bottom: 20px;
    margin-top: -10px;
}
.error{
    color:rgba(136, 5, 13, 1);
    font-weight: 100;
    font-size: var(--chakra-fontSizes-sm);
}

.submit_btn{
    background: rgba(136, 5, 13, 1) !important;
    margin-top: 25px!important;
    border-radius: 20px!important;
    height: 44px!important;
    transition: all 0.3s ease-in-out;
    width: 65%;
    margin: auto 19%;
}
.submit_btn:hover{
    background: rgb(151, 68, 74) !important;
}
@media screen and (max-width:860px){
    .form{
        width:80%;
        margin-top: 20px;

    }
}