.list_menu{
    max-height: 500px;
    min-height: 90px;
    overflow-y: scroll;
    margin: 6px;
    &::-webkit-scrollbar {
        width: 7px!important;               /* width of the entire scrollbar */
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
      &::-webkit-scrollbar-track {
        background: transparent!important;      /* color of the tracking area */
      }
      &::-webkit-scrollbar-thumb {
        background-color: #50340d94!important;
        border-radius: 20px!important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #000000da!important;
        border-radius: 20px!important;
      }
}
.li{

}
.li_customer{

}
.li_name_title{
    padding: 4px;
}
.li_customer_name{
    color: darkred;
    font-weight: 600!important;
    font-family: -webkit-body;
    font-size: 0.9rem;
}
.li_title{
    font-size: 0.78rem;
    font-weight: 500;
    color: rgb(40, 40, 40);
    font-family: -webkit-body;
}
.li_customer_image{
    border-radius: 25px;
    height: 40px;
    object-fit: scale-down;
}
.li_body{
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.79rem;
    font-family: -webkit-body;
    color: #9a9a9a;
}
.li_timestamp{
    font-size: 0.8rem;
    color: #cca56b;
}
.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 0%);
    z-index: -1;
    filter: saturate(0);
    backdrop-filter: brightness(0.6);
}
.no_notifications_content{
    text-align: -webkit-center;
    font-size: small;
    display: grid;
    justify-items: center;
    grid-gap: 8px;
    margin-top: 5px;
    color: darkred;
}

@media screen and (max-width:650px) {
    .li_body {
        max-width: 240px;
        text-align: start!important;
    }
    .li_title{
        font-size: 0.6rem;
    }
}