.navbar {
  /* text-align: center; */
  /* justify-content: center;
    display: flex;
    gap: 3%;
    box-shadow: 0px 0px 8px #00000040; */

  justify-content: space-between;
  display: flex;
  padding: 0.4% 10.9%;
  background-image: url("../../../assets/images/imgs/layer.png");

 background-position: "center";
 background-size: cover;
 background-repeat: no-repeat;
 
}
.navbar_li {
  /* color: black; */
}
.navbar_li_active {
  /* color: red; */
}

.navbar_li_drawer{
  margin-top: 20px;
}

.Badge {
}
.Badge_Content {
}

.searchHeader {
  justify-content: space-between;
  display: flex;
  align-items: "center";
  background-color: "#D9D9D980";
  padding: 0.5% 10.9%;
  /* height: 4.3em; */
}
.navbar_li{
  color: rgb(255, 255, 255);
  text-align: center;
  justify-content: center;
  display: flex;
  padding: .8rem;
}
.navbar_li_active{
  color: #6f0000;
  /* padding: .2rem; */
    padding: .8rem;
    
  background: #EFEBE3;
  font-weight: bold;
}