 

 
.li{
  
    cursor: pointer;
}
.li_active{
    
    cursor: pointer;
    color: #88050D;
   

   
}

 
.LogOut_li:hover{
    color: #88050D;
}
@media screen and (max-width:1075px){
    .sideMenu{
        width: 35%;
    } 
    
}
@media screen and (max-width:860px){
    .sideMenu{
        width: 100%;
    } 
    .li_container{
        display: flex;
        justify-content: center;
    }
    .header{
        font-weight: 500;
        text-align: center;
    }
}

@media screen and (max-width:425px){
    .li_container{
        font-size: 0.75rem;
    }
}