

/*--------------------------M E N U--------------------------- */
.Welcome_Content{
    opacity: 1;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
    transform: scale(1) skew(0deg, 0deg) translateX(0px);
    position: relative;
    background: white;
    border-radius: 25px;
}
.Welcome_Content_hide{
    opacity: 0;
    height: 0;
    visibility: hidden;
    transform: scale(0.8) skew(-5deg, -10deg) translateX(200px); /* Combine scale and translateX */
    background: white;
}
.Chat_Wrapper{
    opacity: 1;
    height: calc(100% + 20px);
    min-height: calc(100% + 16px); 
    transform: scale(0.95) translateX(0px);
    transition: all 0.8s ease-in-out;
    background: white;
    border-radius: 25px;
}
.Chat_Wrapper_hide{
    opacity: 0;
    height: 0px;
    transform: scale(1) translateX(200px); /* Combine scale and translateX */
}
/* .Chat_Wrapper_hide .chat_nav{
    opacity: 0;
} */
.Chat_Container{
    position: inherit;
    height: 510.7px;
    min-height: 510.7px;
    width: 300px;
    min-width: 300px;
    display: block;
    background: #ffffff00;
    bottom: 120px;
    right: 20px;
    border-radius: 8px;
    text-align: center;
    transition: all 0.5s ease-in-out;
}
.Chat_Container_active{ transform: translateX(0px); opacity: 1; filter: blur(0px);}
.Chat_Container_unactive{ transform: translateX(350px); opacity: 0; filter: blur(11px);}

.welcome_header{
    padding: 45px;
    margin-top: -20px;
    font-size: 1.25rem;
    margin-bottom: -40px;
}

.start_buttons_box{
 padding: 5px;
}

.start_Btn{
    width: 100%;
    justify-content: center;
    color: white;
    padding: 10px;
    border-radius: 25px;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: 0px 5px 11px rgb(206, 206, 206);
    transition: all 0.3s ease-in-out;
}
.start_Btn:hover{
    box-shadow: 0px 5px 11px grey;
}
.start_Btn_support{
    background: #88050D;
}
.start_Btn_support:hover{
    background: #69040c;
}
.start_Btn_customer{
    background: #0A806A;
}
.start_Btn_customer:hover{
    background: #045e4d;
}
.empty_image{
    height: 277.24px;
    object-fit: scale-down;
    padding: 40px;
    margin-bottom: -10px;
}

.chat_nav{
    display: flex;
    justify-content: space-between;
    background: #88050D;
    margin-bottom: 0;
    border-top-left-radius: 25px;
    padding: 5px;
    border-top-right-radius: 25px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}
.chat_nav_li{
    text-align: start;
    width: 100%;
    padding: 8px;
    color: #ffffff;
    margin-top: 4px;
    cursor: pointer;
}
.chat_nav_li_active{
    color: #88050D;
    border-bottom:solid 3px #88050D ;
}
.close_icon{
    text-align: center;
    width: 40%;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    color: #914c4c;
}



.Chat_Box{

}

.Chat_Box_header{
    background: #0A806A;
    color: white;
    padding: 6px;
    gap: 5px;
    box-shadow: 0px 11px 8px -10px grey;
}
.Chat_Box_header_image{
    border-radius: 50px;
}

.Chat_Box_header_name{
    align-self: center;
    font-weight: 100!important;
    font-family: ui-sans-serif;
}
.back_arrow{
    font-size: 2rem;
    align-items: center;
    cursor: pointer;
    color: #c4c4c4;
    transition: all 0.3s ease-in-out;
}
.back_arrow:hover{
    color: #ffffff;
}
/*------------------------------L I S T  C O N T A I N E R----------------------------------------*/
.List_Container{
    background: #ffffff;
    border-radius: 20px!important;
    margin-left: 10px!important;
    margin-right: 10px!important;
    max-height: 470px;
    min-height: 470px;
    overflow-y: scroll;
    transform: scale(.94);
    overflow-x: hidden;
}

.list_li{
    background: linear-gradient(45deg,#ffeed6,#fff);
    border-bottom: 1px solid #cfcfcf;
    cursor: pointer;
    gap: 4px;
    padding: 10px;
    transition: all .5s ease-in-out;
}
.list_li:hover{
    background: linear-gradient(45deg, rgb(255, 225, 184), white);
    /* border-bottom-left-radius: 15px; */
    box-shadow: inset 0px 0px 15px 1px #4f4f4f7d;
}
.list_li_img{
    width: 39px!important;
    height: 40px;
    object-fit: scale-down;
    border-radius: 55px;
}
.list_li_name{
    text-align: start;
}
.list_li_date{
    color: #807f7f;
    font-family: ui-sans-serif;
    font-size: .68rem;
    font-weight: 100;
    position: relative;
    right: -15px;
    text-align: end;
}
.list_li_message{
    width: 200px;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* position: relative; */
    text-align: start;
}
.list_unreadCount{
    background: #F44336;
    color: white;
    height: 22px;
    width: 35px;
    margin: -10px;
    margin-right: -5px;
    margin-top: 0px;
    border-radius: 30px;
    padding: 3.85px;
    font-size: 0.7rem;
}

/*------------------------------C H A T  B O D Y----------------------------------------*/
.Chat_Body{
    grid-gap: 10px;
    overflow-y: scroll;
    background: #FAF9F9;
    display: flex;
    flex-direction: column-reverse;
}
.message_li_content{
    display: block;
}
.message_li{
    text-align: end;
    max-width: 80%;
    padding: 10px;
    margin: 1.2%;

}

.message_li_right{
    text-align: end;
    float: inline-end;
    background: #EAC07F;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
}
.message_li_left{
    text-align: start;
    float: inline-start;
    background: #ebebeb;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
}


/* .message_li_right:after{
    content: ' ';
    position: relative;
    left: 0px;
    right: auto;
    top: 32px;
    bottom: -20px;
    border: 31px solid;
    border-color: #dcdcdc #e7e7e700 #9e3c3c00 #d7d7d700;
    border-right-width: thick;
    z-index: -5;
}
.message_li_left:after{
    content: ' ';
    position: relative;
    left: 0px;
    right: auto;
    top: 4px;
    bottom: -20px;
    border: 12px solid;
    border-color: #dcdcdc00 #e7e7e700 #9e3c3c00 #eddfc9;
    border-right-width: thick;
    z-index: -5;
} */

.message_input{
    width: 85%;
    border: none!important;
    outline: none !important;
    box-shadow: none!important;
    border-radius: 0px !important;
}
.Send_Button{
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.send_btn_flex{
    align-items: center;
    width: 15%;
    justify-content: center;
    cursor: pointer;
}
.attach_btn_flex{
    align-items: center;
    font-size: 1.3rem;
    color: darkgrey;
    cursor: pointer;
}
.Text{

}
.date{
    font-size: 0.65rem;
    color: #606060;
    margin-top: -3px;
    margin-bottom: -17px;
    position: relative;
    text-align: unset;
    top: 9px;
}